<template>
  <v-app>
    <app-bar />
    <v-main>
      <router-view />
    </v-main>
    <app-footer @onCookiesSetting="onCookiesSetting" />
    <cookies-setting
      :showCookiesModal="showCookiesModal"
      @onCookiesSetting="onCookiesSetting"
      @onSaveSettings="showCookiesModal = false"
    />
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import AppFooter from "./components/AppFooter.vue";
import CookiesSetting from "./components/CookiesSetting.vue";


export default {
  name: "App",
  components: {
    AppBar,
    AppFooter,
    CookiesSetting,
  },
  data() {
    return {
      showCookiesModal: false,
    };
  },
  methods: {
    onCookiesSetting() {
      this.showCookiesModal = true;
    },
  },
};
</script>
