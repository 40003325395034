import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueGtag from 'vue-gtag';
import { bootstrap } from 'vue-gtag';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: {
    id: 'G-1NP479D3TC',
  },
  enabled: false,
  bootstrap: false,
});

bootstrap().then(() =>
  window.gtag('config', 'G-1NP479D3TC', { debug_mode: true })
);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
