import { render, staticRenderFns } from "./TourPartners.vue?vue&type=template&id=63e4f89f&scoped=true&"
import script from "./TourPartners.vue?vue&type=script&lang=js&"
export * from "./TourPartners.vue?vue&type=script&lang=js&"
import style0 from "./TourPartners.vue?vue&type=style&index=0&id=63e4f89f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e4f89f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardText,VCardTitle,VImg})
