<template>
  <v-timeline id="tour-timeline" :dense="$vuetify.breakpoint.smAndDown">
    <v-timeline-item v-for="tour in tours" :key="tour.town">
      <span slot="opposite" class="text-h4"> {{ tour.date }}</span>
      <v-card class="elevation-2">
        <v-card-title class="text-h6"> {{ tour.place }}</v-card-title>
        <v-card-subtitle> {{ tour.town }} </v-card-subtitle>
        <v-card-text>
          <div class="d-md-flex">
            <v-avatar class="mr-6" color="grey" size="164" round>
              <v-img :src="tour.guarantorImg"></v-img>
            </v-avatar>
            <div>
              <div class="text-h6">Odborný garant: {{ tour.guarantor }}</div>
              <div class="mt-4">
                {{ tour.guarantorText }}
              </div>
            </div>
          </div>
          <div>
            <div class="text-h6">Program</div>
            <v-divider class="mb-4" />
            <div class="shedule-wrapper grey lighten-4 pa-4">
              <div
                v-for="schedule in tour.schedule"
                :key="schedule.lecturer"
                class="mb-4"
              >
                <div class="d-md-flex">
                  <div class="mr-8 schedule-time">
                    {{ schedule.time }}
                  </div>
                  <div>
                    <strong>{{ schedule.lecturer }}</strong>
                    <div>{{ schedule.topic }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8 text-h6">Informace o kongresu</div>
          <v-divider class="mt-2 mb-4" />
          <div class="mb-2">
            <v-icon color="primary" class="mr-2" small> mdi-calendar </v-icon
            >{{ tour.date }}
          </div>
          <div class="mb-2">
            <v-icon color="primary" class="mr-2" small>mdi-map-marker</v-icon
            >{{ tour.town }} -
            {{ tour.address }}
          </div>
          <div class="mb-2">
            <v-icon color="primary" class="mr-2" small>mdi-home</v-icon>
            {{ tour.place }}
          </div>
          <div>
            <v-icon color="primary" class="mr-2" small>mdi-link-variant</v-icon>
            <a :href="tour.placeUrl" target="_blank">Informace o místě</a>
          </div>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import tours from "../assets/tours.json";

export default {
  name: "TourTimeline",
  data() {
    return {
      tours: tours,
    };
  },
};
</script>

<style lang="scss" scoped>
.see-more-btn {
  cursor: pointer;
  font-weight: 500;
  color: var(--v-primary-base);
  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

.schedule-time {
  white-space: nowrap;
}
</style>
