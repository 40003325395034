<template>
  <div id="tour-tabs">
    <h1 class="text-center mb-0">Program tour</h1>
    <div class="divider" />
    <div class="registration-btn">
      <v-btn
        depressed
        rounded
        color="primary"
        class="text-h6 px-6 py-5"
        @click="onRegistrationBtn"
        >Registrace na tour</v-btn
      >
    </div>

    <v-tabs
      v-if="$vuetify.breakpoint.mdAndUp"
      color="primary"
      left
      class="tab-wrapper"
    >
      <v-tab v-for="component in components" :key="component.title">
        <v-icon class="mr-2">{{ component.icon }}</v-icon>
        {{ component.title }}</v-tab
      >
      <v-tab-item
        color="transparent"
        v-for="component in components"
        :key="component.name"
        class="pa-12"
      >
        <component
          :is="component.name"
          @onSeeMoreBtn="onSeeMoreBtn"
        ></component>
      </v-tab-item>
    </v-tabs>

    <div v-else class="pa-2 mb-8">
      <tour-timeline />
    </div>
  </div>
</template>

<script>
import TourMap from "../components/TourMap.vue";
import TourTimeline from "../components/TourTimeline.vue";

export default {
  name: "TourTabs",
  components: {
    TourMap,
    TourTimeline,
  },
  data() {
    return {
      components: [
        { name: "tour-map", title: "Mapa", icon: "mdi-map-legend" },
        {
          name: "tour-timeline",
          title: "Časová osa",
          icon: "mdi-timeline-text-outline",
        },
      ],
    };
  },
  methods: {
    onSeeMoreBtn(value) {
      this.$emit("onSeeMoreBtn", value);
    },
    onRegistrationBtn() {
      this.$emit("onRegistrationBtn");
      this.$gtag.event("registration-click-tabs", {
        value: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#tour-tabs {
  position: relative;

  .registration-btn {
    text-align: center;
    margin-bottom: 2rem;
    @media screen and (min-width: 960px) {
      margin-bottom: 0;
      .v-btn {
        position: absolute;
        right: 1rem;
        top: 9rem;
        z-index: 1;
      }
    }
  }
  .divider {
    border-top: 6px solid var(--v-accent-base);
    max-width: 12rem;
    margin: auto;
    border-radius: 100rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .tab-wrapper {
    border: 1px solid gainsboro;
    border-radius: 1rem;
    margin-bottom: 4rem;

    ::v-deep .theme--light.v-tabs-items {
      background: transparent;
    }
  }
}
</style>