<template>
  <div class="cookies-modal">
    <div class="cookies-modal-card">
      <div class="cookies-modal-card__header">
        <img
          src="https://general-storage.b-cdn.net/cookie_logo.svg"
          alt="cookie icon"
          width="70"
        />
        <div>Nastavení cookies</div>
      </div>

      <div class="cookies-modal-card__body">
        <div class="cookies-setting">
          <div>
            <div class="cookies-setting__title">Technické cookies</div>
            <div class="cookies-setting__text">
              Technické cookies jsou nezbytné pro správné fungování webu a všech
              funkcí, které nabízí. Tyto cookies pomáhají, aby byla webová
              stránka použitelná tak, že umožní základní funkce jako navigace
              stránky a přístup k zabezpečeným sekcím webové stránky. Webová
              stránka nemůže správně fungovat bez těchto cookies.
            </div>
          </div>
          <div class="necessary-cookie-icon">
            <img
              src="https://general-storage.b-cdn.net/correct_sign.svg"
              width="40"
            />
          </div>
        </div>
        <div class="cookies-setting">
          <div>
            <div class="cookies-setting__title">Analytické cookies</div>
            <div class="cookies-setting__text">
              Analytické cookies pomáhají majitelům webových stránek, aby
              porozuměli, jak návštěvníci používají webové stránky. Anonymně
              sbírají a sdělují informace.
            </div>
          </div>
          <label class="switch">
            <input v-model="analyticsCookies" type="checkbox" />
            <span class="slider" />
          </label>
        </div>
      </div>
      Tento web používá cookies. Díky nim vidíte jen to, co skutečně chcete.
      <a
        href="https://soubory-vzdelavani-l.b-cdn.net/Cookies.pdf"
        target="_blank"
        >Více informací</a
      >
      <div class="cookies-modal-card__actions">
        <button class="secondary-btn" @click="onSaveSettings">
          Uložit nastavení
        </button>
        <button class="primary-btn" @click="onAcceptAll">Přijmout vše</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookiesModal",
  props: {
    savedAnalyticsCookies: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      analyticsCookies: false,
    };
  },
  mounted() {
    if (localStorage.getItem("cookiesSettings")) {
      const getCookiesSetting = JSON.parse(
        localStorage.getItem("cookiesSettings")
      );
      this.analyticsCookies = getCookiesSetting.analyticsCookies;
    }
  },
  methods: {
    onSaveSettings() {
      this.$emit("onSaveSettings", {
        analyticsCookies: this.analyticsCookies,
      });
    },
    onAcceptAll() {
      this.$emit("onSaveSettings", {
        analyticsCookies: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cookies-modal {
  width: 100%;
  height: 100vh;
  background: rgba(128, 128, 128, 0.582);
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  justify-items: center;
  align-items: center;
}

.necessary-cookie-icon {
  margin-left: 2rem;
}

.cookies-modal-card {
  max-width: 50rem;
  max-height: 100%;
  overflow-y: auto;
  background: white;
  padding: 2rem;
  margin: auto;
  &__header {
    display: flex;
    font-size: 2rem;
    color: black;
    align-items: center;
    margin-bottom: 2rem;

    img {
      margin-right: 1rem;
    }
  }

  &__actions {
    margin-top: 2rem;
    text-align: right;

    button {
      padding: 0.5rem 1rem;
      width: 10rem;
    }

    .secondary-btn {
      background: whitesmoke;
      border: 1px solid gray;
      margin-right: 1rem;
    }

    .primary-btn {
      background: var(--v-primary-base);
      color: white;
      border: 1px solid var(--v-primary-base);
    }

    @media screen and (max-width: 420px) {
      button {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.cookies-setting {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid gainsboro;
  &__title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

//switch styles
.switch {
  position: relative;
  display: inline-block;
  min-width: 60px;
  margin-left: 2rem;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--v-primary-base);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--v-primary-base);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>