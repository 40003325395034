<template>
  <div id="tour-intro">
    <h1 class="mb-0 text-center">Proč se zúčastnit</h1>
    <div class="divider" />
    <div class="tour-intro-wrapper mx-auto">
      <v-row class="topics-card">
        <v-col cols="12" md="3" v-for="item in items" :key="item.id">
          <div class="circle-number mx-auto mb-4">{{ item.id }}</div>
          <div class="topic card">{{ item.title }}</div>
        </v-col>
      </v-row>
      <div
        class="
          d-flex
          justify-space-between
          align-center
          pa-2 pa-md-0
          flex-column flex-md-row
        "
      >
        <v-img
          src="../assets/img/certificate.svg"
          width="100%"
          max-width="37rem"
        ></v-img>
        <div>
          <div class="card image-card">
            <h3>KREDITY ČLK</h3>
            <div>
              Vzdělávací akce je pořádána dle stavovského předpisu České
              lékařské komory č. 16 a účast na tour bude ohodnocena kredity
              celoživotního vzdělávání.
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          d-flex
          justify-space-between
          align-center
          pa-2 pa-md-0
          flex-column-reverse flex-md-row
        "
      >
        <div>
          <div class="card image-card">
            <h3>REGISTRACE NA TOUR</h3>
            <div class="mb-8">
              Účast na tour je ZDARMA, kapacita je omezena. Registrujte se
              kliknutím na tlačítko níže.
            </div>
            <div>
              <v-btn
                color="primary"
                class="text-h6 py-5 px-6"
                rounded
                depressed
                @click="onRegistrationBtn"
                >ZAREGISTROVAT SE</v-btn
              >
            </div>
          </div>
        </div>
        <v-img
          width="100%"
          max-width="37rem"
          src="../assets/img/registration.svg"
        >
        </v-img>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { id: 1, title: "Tour se špičkovou odbornou účastí." },
        {
          id: 2,
          title:
            "Přednášky na aktuální diskutovaná témata z oblasti dermatologie a souvisejících oborů.",
        },
        { id: 3, title: "Účast zdarma – počet míst je omezen." },
        { id: 4, title: "Možnost získání kreditů ČLK." },
      ],
    };
  },
  methods: {
    onRegistrationBtn() {
      this.$emit("onRegistrationBtn");
      this.$gtag.event("registration-click-intro", {
        value: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#tour-intro {
  margin-bottom: 10rem;
}

.divider {
  border-top: 6px solid var(--v-accent-base);
  max-width: 12rem;
  margin: auto;
  border-radius: 100rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.tour-intro-wrapper {
  max-width: 80rem;
}

.topics-card {
  margin: 2rem 0 8rem 0;
}
.circle-number {
  width: 5rem;
  height: 5rem;
  background: var(--v-primary-base);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 2rem;
  border-radius: 5rem;
  color: white;
  margin-right: 1rem;
  border: 5px solid white;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.card {
  background: whitesmoke;
  border-radius: 1rem;
  border: 1px solid gainsboro;
  border-radius: 1rem;
  padding: 1.5rem;
  font-size: 1.2rem;
}

.topic {
  min-height: 12rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-card {
  max-width: 35rem;
}
</style>