<template>
  <div id="app-bar">
    <v-app-bar>
      <v-img src="../assets/img/logo.png" max-width="250" />
    </v-app-bar>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
