<template>
  <div>
    <div id="home">
      <tour-hero @onRegistrationBtn="showRegistrationModal = true" />
      <tour-intro @onRegistrationBtn="showRegistrationModal = true" />
      <tour-tabs
        @onRegistrationBtn="showRegistrationModal = true"
        @onSeeMoreBtn="seeMore"
      />
      <tour-partners />
      <tour-modal
        :tour="tour"
        :showTourDialog="showTourDialog"
        @closeDialog="showTourDialog = false"
      />
      <registration-modal
        :showRegistrationModal="showRegistrationModal"
        @closeRegistrationDialog="showRegistrationModal = false"
        @showNotification="showNotification = true"
      />
    </div>
    <v-snackbar
      v-model="showNotification"
      color="success"
      outlined
      fixed
      :timeout="5000"
      top
      right
    >
      <div class="d-flex">
        <v-icon color="success" class="mr-2">mdi-check</v-icon>
        <div class="text-subtitle-1">Registrační byl formulář odeslán</div>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import TourHero from "../components/TourHero.vue";
import TourIntro from "../components/TourIntro.vue";
import TourTabs from "../components/TourTabs.vue";
import TourModal from "../components/TourModal.vue";
import TourPartners from "../components/TourPartners.vue";
import RegistrationModal from "../components/RegistrationModal.vue";

export default {
  components: {
    TourTabs,
    TourHero,
    TourModal,
    TourIntro,
    TourPartners,
    RegistrationModal,
  },
  name: "Home",
  data() {
    return {
      tour: {},
      showTourDialog: false,
      showRegistrationModal: false,
      showNotification: false,
    };
  },
  methods: {
    seeMore(value) {
      this.tour = value;
      this.showTourDialog = true;
    },
  },
};
</script>

<style lang="scss">
#home {
  max-width: 1500px;
  margin: auto;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }

  h3 {
    color: gray;
    margin-bottom: 1rem;
  }
}
</style>
