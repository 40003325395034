<template>
  <div>
    <cookies-bar
      v-if="showCookiesBar"
      @onSettingButton="onSettingButton"
      @onAcceptAll="onAcceptAll"
    />
    <cookies-modal
      v-if="showCookiesModal"
      :savedAnalyticsCookies="settings.analyticsCookies"
      @onSaveSettings="onSaveSettings"
    />
  </div>
</template>

<script>
import CookiesBar from "../components/CookiesBar.vue";
import CookiesModal from "../components/CookiesModal.vue";

export default {
  name: "CookiesSettings",
  components: { CookiesBar, CookiesModal },
  props: {
    showCookiesModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showCookiesBar: false,
      settings: {
        analyticsCookies: false,
      },
    };
  },
  mounted() {
    if (!localStorage.getItem("cookiesSettings")) {
      this.showCookiesBar = true;
    } else {
      const getCookiesSetting = JSON.parse(
        localStorage.getItem("cookiesSettings")
      );
      this.settings = getCookiesSetting;
      if (this.settings.analyticsCookies) {
        this.$gtag.optIn();
      }
    }
  },
  methods: {
    onSettingButton() {
      this.showCookiesBar = false;
      this.$emit("onCookiesSetting");
    },
    onAcceptAll() {
      this.showCookiesBar = false;
      localStorage.setItem(
        "cookiesSettings",
        JSON.stringify({ analyticsCookies: true })
      );
      this.$gtag.optIn();
    },
    onSaveSettings(value) {
      localStorage.setItem("cookiesSettings", JSON.stringify(value));
      const getCookiesSetting = JSON.parse(
        localStorage.getItem("cookiesSettings")
      );
      this.settings = getCookiesSetting;
      if (this.settings.analyticsCookies) {
        this.$gtag.optIn();
      } else {
        this.$gtag.optOut();
      }
      this.$emit("onSaveSettings");
    },
  },
};
</script>