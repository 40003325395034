<template>
  <div id="tour-map"><czech-map @onSeeMoreBtn="onSeeMoreBtn" /></div>
</template>

<script>
import CzechMap from "./svg/CzechMap.vue";
export default {
  components: { CzechMap },
  methods: {
    onSeeMoreBtn(value) {
      this.$emit("onSeeMoreBtn", value);
    },
  },
};
</script>

<style lang="scss" scoped>
#tour-map {
  width: 100%;
}
</style>
