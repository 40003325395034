<template>
  <div id="tour-partners">
    <div
      class="pa-4 pa-lg-0 d-flex justify-space-between flex-column flex-lg-row"
    >
      <div
        class="partner-card-wrapper"
        v-for="partner in partners"
        :key="partner.name"
      >
        <a
          :href="partner.link"
          target="_blank"
          rel="noopener noreferrer"
          class="text-decoration-none"
        >
          <v-card rounded="lg">
            <v-card-title class="justify-center grey--text text--darken-1">
              {{ partner.title }}
            </v-card-title>
            <v-card-text :class="`partner-${partner.name}`">
              <v-img
                :src="require(`../assets/img/${partner.img}`)"
                height="7rem"
                contain
              ></v-img>
            </v-card-text>
          </v-card>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TourPartners",
  data() {
    return {
      partners: [
        {
          name: "sanofi",
          title: "Partner kurzu",
          img: "Sanofi_logo.png",
          link: "https://www.sanofi.cz/",
        },
        {
          name: "EASI score",
          title: "Partner kurzu",
          img: "EASI_logo.png",
          link: "https://easiscore.cz/kalkulator",
        },
        {
          name: "TME",
          title: "Partner kurzu",
          img: "TME_logo.png",
          link: "https://www.tmesolutions.cz/",
        },
        {
          name: "atopie",
          title: "Partner kurzu",
          img: "Atopie_logo.svg",
          link: "https://www.atopie-online-mezioborove.cz/odborne-kolegium",
        },
        {
          name: "corbiere",
          title: "Pořadatel kongresu",
          img: "corbiere_logo.png",
          link: "https://corbiere.cz/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#tour-partners {
  margin-bottom: 4rem;

  .partner-card-wrapper {
    margin-bottom: 1rem;
    .v-card {
      height: 100%;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
    @media screen and (min-width: 1264px) {
      width: 19%;
    }
  }

  .partner-corbiere {
    background-color: rgb(158, 158, 158);
    padding-top: 1rem;
  }

  .partner-atopie {
    padding-top: 1rem;
    background-color: #017dbd;
  }
}
</style>