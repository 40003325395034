<template>
  <v-dialog
    v-model="showRegistrationModal"
    persistent
    max-width="700"
    content-class="registration-dialog"
  >
    <div class="text-right">
      <v-btn icon @click="$emit('closeRegistrationDialog')">
        <v-icon color="white" large>mdi-close</v-icon>
      </v-btn>
    </div>

    <iframe
      height="900"
      src="https://docs.google.com/forms/d/e/1FAIpQLSfHub0ZYTIWk6T-c94GiEZCX7z_LNkNQWl4cY1k04jcuKDwqA/viewform"
      frameborder="0"
      style="display: block; vertical-align: bottom"
    ></iframe>
    <!-- <v-card>
      <v-card-title class="pt-8">
        <div class="text-h4">Registrační formulář</div></v-card-title
      >
      <v-card-text class="pt-4">
        <v-form v-model="validForm" ref="form" @submit.prevent="onSubmit">
          <v-text-field
            filled
            v-model="doctor.name"
            :rules="requiredField"
            label="Jméno a přimení a tituly"
          ></v-text-field>
          <v-text-field
            filled
            v-model="doctor.email"
            :rules="validEmail"
            label="E-mail"
          ></v-text-field>
          <v-text-field
            filled
            v-model="doctor.workplace"
            :rules="requiredField"
            label="Název pracoviště"
          ></v-text-field>
          <v-text-field
            filled
            v-model="doctor.cmcId"
            :rules="requiredField"
            label="Identifikační číslo ČLK"
          ></v-text-field>

          <div class="text-right">
            <v-btn
              depressed
              color="primary"
              text
              class="mr-2"
              @click="$emit('closeRegistrationDialog')"
              >Zavřít</v-btn
            >
            <v-btn type="submit" depressed color="primary"
              >Dokončit registraci</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card> -->
  </v-dialog>
</template>

<script>
export default {
  name: "RegistrationModal",
  props: {
    showRegistrationModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      doctor: {},
      validForm: false,
      requiredField: [(v) => !!v || "Pole je povinné"],
      validEmail: [
        (v) => !!v || "Email je povinný",
        (v) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail má špatný formát",
      ],
    };
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
    },
    onSubmit() {
      this.$refs.form.validate();
      if (this.validForm) {
        const doctor = this.doctor;
        console.log(doctor);
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: this.encode({ "form-name": "registration-form", ...doctor }),
        })
          .then(() => {
            this.$emit("closeRegistrationDialog");
            this.$emit("showNotification");
          })
          .catch(() => {
            alert("Chyba při odesílání formuláře");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .registration-dialog {
  box-shadow: none;
}
</style>