<template>
  <v-footer color="secondary" class="mt-12">
    <div class="inner-footer-wrapper">
      <div class="mb-4 mb-md-0">
        <h2>Kontakt</h2>
        <div>tel: <a href="tel:+420735510725">735 510 725</a></div>
        <div>
          e-mail:
          <a href="mailto:praconova@tmesolutions.cz"
            >pracnova@tmesolutions.cz</a
          >
        </div>
      </div>
      <div>
        <h2>Cookies</h2>
        <div>
          <a
            @click="$emit('onCookiesSetting')"
            class="text-decoration-underline"
            >Nastavení cookies</a
          >
        </div>
      </div>
      <div>
        <div>
          <a href="https://storyset.com/people" target="_blank"
            >People illustrations by Storyset</a
          >
        </div>
        <div>© Corbiere 2022</div>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.inner-footer-wrapper {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  color: white;
  padding: 2rem 0;

  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: white;
  }
}
</style>