<template>
  <v-dialog
    v-model="showTourDialog"
    max-width="850"
    @click:outside="$emit('closeDialog')"
  >
    <v-card>
      <v-card-text class="pt-8">
        <div class="d-flex flex-column flex-md-row mb-8">
          <v-avatar class="mr-6 mb-4 mb-md-0" color="grey" size="164" round>
            <v-img :src="tour.guarantorImg"></v-img>
          </v-avatar>
          <div>
            <div class="text-h6">Odborný garant: {{ tour.guarantor }}</div>
            <div class="mt-4">
              {{ tour.guarantorText }}
            </div>
          </div>
        </div>
        <div>
          <div class="text-h6">Program</div>
          <v-divider class="mb-4" />
          <div class="shedule-wrapper grey lighten-4 pa-4">
            <div
              v-for="schedule in tour.schedule"
              :key="schedule.lecturer"
              class="mb-4"
            >
              <div class="d-md-flex">
                <div class="mr-8 schedule-time">
                  {{ schedule.time }}
                </div>
                <div>
                  <strong>{{ schedule.lecturer }}</strong>
                  <div>{{ schedule.topic }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8 text-h6">Informace o kongresu</div>
        <v-divider class="mt-2 mb-4" />
        <div class="mb-2">
          <v-icon color="primary" class="mr-2" small> mdi-calendar </v-icon
          >{{ tour.date }}
        </div>
        <div class="mb-2">
          <v-icon color="primary" class="mr-2" small>mdi-map-marker</v-icon
          >{{ tour.town }} -
          {{ tour.address }}
        </div>
        <div class="mb-2">
          <v-icon color="primary" class="mr-2" small>mdi-home</v-icon>
          {{ tour.place }}
        </div>
        <div>
          <v-icon color="primary" class="mr-2" small>mdi-link-variant</v-icon>
          <a :href="tour.placeUrl" target="_blank">Informace o místě</a>
        </div>
        <div v-if="tour.hotelImg" class="mt-4">
          <v-img :src="require(`../assets/img/${tour.hotelImg}`)"></v-img>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    tour: {
      type: Object,
      required: true,
    },
    showTourDialog: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    showTourDialog() {
      if (this.showTourDialog === true) {
        setTimeout(() => {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-time {
  white-space: nowrap;
}
</style>
