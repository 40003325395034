<template>
  <div id="tour-hero">
    <v-container>
      <v-row>
        <v-col cols="12" md="5" class="pr-md-12">
          <v-img
            src="../assets/img/DERMAcademy_logo_borderless.png"
            contain
            max-width="30rem"
            class="mb-4 mx-auto"
          ></v-img>
          <div class="text-h2 mb-10 text-center">2022</div>
          <div class="text-h6 grey--text font-weight-regular">
            Zveme vás na DERMAcademy Tour 2022 po České republice se špičkovou
            odbornou účastí. Připojte se k akci v kterémkoli z&nbsp;8&nbsp;měst
            a buďte u nejzajímavějších přednášek z&nbsp;dermatologie.
          </div>
          <div
            class="d-flex align-center mt-12 text-center flex-column flex-md-row"
          >
            <v-btn
              depressed
              class="text-h6 px-8 py-6 mb-4 mb-md-0 mr-md-4"
              @click="$vuetify.goTo('#tour-tabs', 'linear')"
            >
              Zobrazit program
            </v-btn>
            <v-btn
              depressed
              class="text-h6 px-8 py-6"
              :class="{ 'v-btn--filled': animateBtn }"
              @click="onRegistrationBtn"
              >Registrace na tour</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12" md="7" class="pl-12">
          <small-czech-map />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SmallCzechMap from "./svg/SmallCzechMap.vue";
export default {
  components: { SmallCzechMap },
  name: "TourHero",
  data() {
    return {
      animateBtn: false,
      gtagOn: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.animateBtn = true;
    }, 500);
  },
  methods: {
    onRegistrationBtn() {
      this.$emit("onRegistrationBtn");
      this.$gtag.event("registration-click-hero", {
        value: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#tour-hero {
  min-height: 100vh;
  h1 {
    font-size: 5rem;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .row {
    @media screen and (min-width: 960px) {
      margin-top: 8%;
    }
  }

  .v-image {
    margin-top: 5rem;
  }

  .v-btn {
    background-color: white;
    color: var(--v-primary-base);
    border: 2px solid var(--v-primary-base);
    border-radius: 2rem;
    transition: background-color 1000ms ease-in-out, color 1050ms ease-in-out;

    &--filled {
      background-color: var(--v-primary-base);
      color: white;
    }
  }
}
</style>
